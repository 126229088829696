<script lang="ts" setup generic="T extends RadioBaseOption = RadioBaseOption">
import { computed } from 'vue'
import { useField } from 'vee-validate'
import type { OptionsIconMap, RadioBaseOption } from '/@src/types/elements-ui'
import { twMerge } from '/@src/styling/ts-merge'

interface FormRadioIconProps {
  name: string
  label: string
  options: OptionsIconMap<T>[]
  vertical?: boolean
  centered?: boolean
  disabled?: boolean
}

const props = withDefaults(defineProps<FormRadioIconProps>(), {})

const { value, errors } = useField<T | undefined>(props.name)

const color = computed(() => {
  if (errors.value.length > 0) {
    return 'danger'
  } else if (value.value) {
    return 'success'
  }
  return undefined
})
</script>

<template>
  <VField :color :label :message="errors.join('\n')">
    <slot name="content"></slot>
    <VRadioIcon v-model="value" :name :options :disabled :vertical :centered />
  </VField>
</template>

<style scoped></style>
