<script setup lang="ts">
import { useGlobalsStore } from '/@src/stores/global'
import { useFocusMunicipalitiesStore } from '/@src/stores/focusMunicipalities'
import { useNationalTargetsStore } from '/@src/stores/nationalTargets'
import type {
  NationalTargetAndValueRecord,
  NormalisedYearlyTargetValues,
} from '/@src/types/national-targets'
import dayjs from 'dayjs'
import type { TailwindColor } from '/@src/types/elements-ui'

const globalsStore = useGlobalsStore()
const focusMunicipalityStore = useFocusMunicipalitiesStore()
const nationalTargetStore = useNationalTargetsStore()

const localCount = computed<number>(() => {
  if (!focusMunicipalityStore.targetStatus) {
    return 0
  }

  const municipalities = Object.values(focusMunicipalityStore.targetStatus)
  return municipalities.reduce((acc, municipality) => {
    if (
      municipality.totalTarget &&
      municipality.matchExpected > municipality.totalTarget
    ) {
      return acc + (municipality.totalTarget ?? 0)
    } else {
      return acc + municipality.matchExpected
    }
  }, 0)
})

const localTargets = computed<NormalisedYearlyTargetValues>(() => {
  if (!focusMunicipalityStore.targetStatus) {
    return {
      bronzeValue: 0,
      silverValue: -1,
      goldValue: 0,
    }
  }
  const municipalities = Object.values(focusMunicipalityStore.targetStatus)
  return municipalities.reduce(
    (acc, municipality) => {
      return {
        bronzeValue: acc.bronzeValue + (municipality.normalisedTarget ?? 0),
        silverValue: -1,
        goldValue: acc.goldValue + (municipality.totalTarget ?? 0) / 4,
      }
    },
    {
      bronzeValue: 0,
      silverValue: -1,
      goldValue: 0,
    },
  )
})

const localTexts = {
  gold: 'Lokaal: Aantal personen die nu gematcht moeten zijn: {value}',
  silver: '',
  bronze: 'Lokaal: Aantal personen die nu gematcht zouden moeten zijn: {value}',
  value: 'Lokaal: Gematchte personen: {value}',
}

const localDisabled = {
  silver: true,
}

const nationCount = computed<number>(() => {
  return (globalsStore.newMatchesCount ?? 0) - localCount.value
})

const nationTargets = computed<NormalisedYearlyTargetValues>(() => {
  const currentTarget = nationalTargetStore.getTargetsInRange(
    dayjs().startOf('year'),
    dayjs(),
  )
  const quarterTarget = nationalTargetStore.getTargetsInRange(
    dayjs().startOf('year'),
    dayjs().endOf('quarter'),
  )

  return {
    bronzeValue: currentTarget.bronzeValue,
    silverValue: -1,
    goldValue: quarterTarget.bronzeValue,
  }
})

const nationTexts = {
  gold: 'Landelijk: Aantal personen die nu gematcht moeten zijn: {value}',
  silver: '',
  bronze: 'Landelijk: Aantal personen die nu gematcht zouden moeten zijn: {value}',
  value: 'Landelijk: Gematchte personen: {value}',
}

const nationDisabled = {
  silver: true,
}

const totalCount = computed<number>(() => {
  return (localCount.value ?? 0) + (nationCount.value ?? 0)
})

const totalTargets = computed<NormalisedYearlyTargetValues>(() => {
  return {
    bronzeValue: localTargets.value.bronzeValue + nationTargets.value.bronzeValue,
    silverValue: -1,
    goldValue: localTargets.value.goldValue + nationTargets.value.goldValue,
  }
})

const totalTexts = {
  gold: 'Totaal: Aantal personen die nu gematcht moeten zijn: {value}',
  silver: '',
  bronze: 'Totaal: Aantal personen die nu gematcht zouden moeten zijn: {value}',
  value: 'Totaal aantal gematchte personen: {value}',
}

const totalDisabled = {
  silver: true,
}

const getValueBarColor = (
  value: number,
  targets: NormalisedYearlyTargetValues,
  disableTargets: Partial<NationalTargetAndValueRecord<boolean>>,
): TailwindColor => {
  if (!value || value < targets.bronzeValue * 0.9) {
    return 'danger'
  } else if (value < targets.bronzeValue) {
    return 'warning'
  } else if (value < targets.silverValue) {
    return 'bronze'
  } else if (value < targets.goldValue) {
    if (disableTargets?.silver) {
      return 'bronze'
    } else {
      return 'silver'
    }
  } else {
    return 'gold'
  }
}
</script>

<template>
  <div>
    <slot
      name="bar-1"
      :value="localCount"
      :targets="localTargets"
      :texts="localTexts"
      :color="getValueBarColor(localCount, localTargets, localDisabled)"
      :disabled="localDisabled"
    />

    <slot
      name="bar-2"
      :value="nationCount"
      :targets="nationTargets"
      :texts="nationTexts"
      :color="getValueBarColor(nationCount, nationTargets, nationDisabled)"
      :disabled="nationDisabled"
    />

    <slot
      name="bar-3"
      :value="totalCount"
      :targets="totalTargets"
      :texts="totalTexts"
      :color="getValueBarColor(totalCount, totalTargets, totalDisabled)"
      :disabled="totalDisabled"
    />
  </div>
</template>

<style scoped></style>
