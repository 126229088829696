<script setup lang="ts">
import { FileStorage } from '/@src/utils/file-list'
import { fromByteArray } from 'base64-js'
import type { MaybeArray } from '/@src/types/utils'
import { toast } from 'vue-sonner'

type FileDropZoneEmits = {
  filesDropped: [files: File[]]
}

interface FileDropZoneProps {
  vertical?: boolean
}

const emits = defineEmits<FileDropZoneEmits>()
const props = defineProps<FileDropZoneProps>()

const dropZone = ref<HTMLElement | null>()

const fileStorage = defineModel<FileStorage>({ required: true })

const imagePreviewUrl = ref<string>()

const clearFiles = () => {
  fileStorage.value.clearFiles()
}

const onDrop = (files: MaybeArray<File> | null) => {
  if (files) {
    if (!Array.isArray(files)) {
      files = [files]
    }

    files
      .filter((f) => f instanceof File) // Filter out non files
      .forEach(async (file) => {
        if (!fileStorage.value.isAcceptableFileType(file)) {
          toast.error('Ongeldig bestandstype')
          return
        }

        const buffer = await file.arrayBuffer()
        const byteArray = new Uint8Array(buffer)
        const base64String = fromByteArray(byteArray)
        fileStorage.value.addFile(file, false, base64String)

        if (['image/jpeg', 'image/png'].includes(file.type)) {
          const reader = new FileReader()
          reader.onload = (e) => {
            imagePreviewUrl.value = e.target?.result?.toString() ?? undefined
          }
          reader.readAsDataURL(file)
        } else {
          imagePreviewUrl.value = undefined
        }
      })
    emits('filesDropped', files)
  }
}

const { isOverDropZone } = useDropZone(dropZone, {
  onDrop: onDrop,
  dataTypes: fileStorage.value.getFileTypes(),
})

const { open: openFileDialog, onChange } = useFileDialog({
  multiple: false,
})

onChange((files) => {
  if (files) {
    onDrop(Array.from(files))
  }
})
</script>

<template>
  <VCard
    ref="dropZone"
    class="drop-zone-area drop-zone-card"
    :class="vertical && 'tw-h-full'"
    :content-class="['tw-pb-0', vertical ? 'tw-h-full' : '']"
    :data-active="isOverDropZone"
    @drop.prevent="onDrop"
  >
    <template #content>
      <div
        class="tw-columns tw-items-center tw-justify-center"
        :class="vertical && 'tw-h-full tw-flex-col'"
      >
        <div class="tw-column" :class="vertical ? 'tw-is-full' : 'tw-is-half'">
          <template v-if="fileStorage.file">
            <h3 class="tw-mb-2 tw-text-center tw-font-alt tw-text-xl tw-font-semibold">
              Geselecteerd bestand: {{ fileStorage.file.file.name }}
            </h3>
            <img
              v-if="imagePreviewUrl"
              :src="imagePreviewUrl"
              alt="Screenshot preview"
              class="image-preview tw-my-3"
            />
            <p>
              Je kan het bestand vervangen door een ander bestand hiernaar toe te slepen
              of door op de "kies een bestand" knop te klikken
            </p>
          </template>
          <h3
            v-else-if="isOverDropZone"
            class="tw-mb-2 tw-text-center tw-font-alt tw-text-xl tw-font-semibold"
          >
            Laat het bestand hier los om het toe te voegen
          </h3>
          <template v-else>
            <h3 class="tw-mb-2 tw-text-center tw-font-alt tw-text-xl tw-font-semibold">
              Geen bestand geselecteerd
            </h3>
            <p>
              Sleep een bestand naar dit gebied of klik op de knop om er een te selecteren
            </p>
          </template>
        </div>
        <div
          class="tw-column !tw-flex tw-flex-row"
          :class="vertical ? 'tw-is-full' : 'tw-is-half'"
        >
          <VButton
            :width="fileStorage.file ? '80' : 'full'"
            icon-left="fa-cloud-upload-alt"
            @click="openFileDialog"
          >
            Kies een bestand
          </VButton>
          <VButton
            v-if="fileStorage.file"
            class="tw-mx-2"
            icon-only="fa-x"
            color="danger"
            @click="clearFiles"
          />
        </div>
      </div>
      <slot :drop-zone-active="isOverDropZone"></slot>
    </template>
  </VCard>
</template>

<style scoped>
.drop-zone-card {
  width: 100%;
  margin: 0 auto;
  transition: 0.2s ease;

  &[data-active='true'] {
    box-shadow: 0 0 10px rgb(0 0 0 / 50%);
    background-color: theme('colors.gray-light.DEFAULT');
  }
}

.is-dark {
  .drop-zone-card[data-active='true'] {
    background-color: theme('colors.gray-dark.DEFAULT');
  }
}

.image-preview {
  object-fit: cover;
  max-height: 20rem;
  width: 100%;
}
</style>
