<script setup lang="ts">
import { computed } from 'vue'
import { useField } from 'vee-validate'
import type { ComponentClass, FAIconName } from '/@src/types/elements-ui'
import type { VInputProps } from '/@src/types/form-props'

type FormInputEmits = {
  enter: []
}

interface FormInputProps extends VInputProps {
  name: string
  label?: string
  hidden?: boolean // Visually hide field (i.e. to help browsers with password storing)
  iconLeft?: FAIconName
  iconRight?: FAIconName
  fieldClass?: ComponentClass
  oldStyle?: boolean
}

defineOptions({
  inheritAttrs: false,
})

const emits = defineEmits<FormInputEmits>()
const props = withDefaults(defineProps<FormInputProps>(), {
  label: undefined,
  type: 'text',
  placeholder: '',
  autocomplete: undefined,
  inputMode: 'text',
  tabIndex: undefined,
  iconLeft: undefined,
  iconRight: undefined,
  fieldClass: undefined,
})

const { value, handleBlur, errors } = useField<string>(props.name)

const color = computed(() => {
  if (errors.value.length > 0) {
    return 'danger'
  } else if (value.value?.length > 0) {
    return 'success'
  }
  return props.color
})
</script>

<template>
  <VField
    :label
    :color
    :message="errors.join('\n')"
    :class="[hidden && 'is-hidden', fieldClass]"
    :icon-left
    :icon-right
    :old-style
  >
    <template v-if="$slots['label']" #label>
      <slot name="label"></slot>
    </template>

    <slot />
    <VInput
      v-model="value"
      v-bind="$attrs"
      :type
      :placeholder
      :autocomplete
      :inputmode="props.inputMode"
      :tabindex
      :step
      :disabled
      :aria-hidden="hidden"
      @blur="handleBlur"
      @keyup.enter="emits('enter')"
    >
    </VInput>
  </VField>
</template>

<style scoped></style>
