import { PressFieldEnum } from '/@src/models/types'
import { UploadableFile } from '/@src/utils/file-list'
import { Dayjs } from 'dayjs'
import type { AbstractId, Address, NullableBoolean } from '/@src/types/utils'
import { z } from 'zod'
import type { FAIconName } from '/@src/types/elements-ui'
import type { ExMatchmakerMatchRequest } from '/@src/types/matchRequests'
import type { ExMatchmakerAdminTask } from '/@src/types/admin-tasks'
import type { CookId } from '/@src/types/cooks'
import type { FoodieId } from '/@src/types/foodies'
import type { TagConnection } from '/@src/types/tags'
import type { CaretakerEnum } from '/@src/types/caretaker'

export type UserId = AbstractId<'user'>
export type UserProfileId = AbstractId<'user-profile'>

export interface User {
  id: UserId

  email: string
  emailVerifiedAt: Dayjs | null
  locale: LanguageEnum
  lastLoginAt: Dayjs | null

  surveyOnHold: boolean

  balance: number
  balanceThreshold: number
  balanceComments: string | null

  isPrepaid: boolean
  blockedAt: Dayjs | null

  hasActiveMatch: boolean
  userProfile: UserProfile
  roles: Role[]
}

export enum UserRoleEnum {
  Cook = 'cook',
  Foodie = 'foodie',

  // Internal
  Admin = 'admin',
  Administrator = 'administrator',

  // Strategy
  Strategy = 'strategy',
  GeneralEmployee = 'general-employee',

  //Operations
  Matchmaking = 'matchmaking',
  Matchmaker = 'matchmaker',
  ExMatchmaker = 'ex-matchmaker',
  Helpdesker = 'helpdesker',
  LeadMatchmaker = 'lead-matchmaker',
  OfficeManager = 'office-manager',

  // Development
  Developer = 'developer',

  // Marketing
  Marketing = 'marketing',
  VolunteerCoordinator = 'volunteer-coordinator',

  // Impact
  ImpactAccelerator = 'impact-accelerator',

  // Management
  Manager = 'manager',

  // Finance
  Finance = 'finance',

  // Third Party
  Reporting = 'reporting',
  ReadOnly = 'read-only',
  External = 'external',
}

export type UserTypeEnum = UserRoleEnum.Cook | UserRoleEnum.Foodie

export type TeamRoleEnum =
  | UserRoleEnum.Developer
  | UserRoleEnum.GeneralEmployee
  | UserRoleEnum.ImpactAccelerator
  | UserRoleEnum.Marketing
  | UserRoleEnum.Matchmaking

export type ExternalRoleType = 'contact-moments'

export interface Role {
  role: UserRoleEnum
  param?: string
}

export interface UserBulkEditParams {
  emailAddresses: string[]
}

export interface UploadVOGParams {
  userId: UserId
  file: UploadableFile
}

export interface PDFResponse {
  error: boolean
  status: 'ok' | undefined
}

export interface PDFFileResponse {
  error: boolean
  response: Response | null
}

export interface ActionsDropdownUser {
  id: number
  userId: UserId
  roleId: CookId | FoodieId | null

  balance: number

  roles: Role[]

  isPrepaid: boolean
  hasActiveMatch: boolean
  fullName: string
  canBeAddedToBatch: boolean
  isAddedToBatch: boolean
  blockedAt: Dayjs | null
}

export interface SearchUsersParams {
  query: string
  limit: number
}

export interface RoleIcon {
  name: string
  icon: FAIconName
}

export interface SearchUser {
  id: UserId
  userId: UserId
  roleId: CookId | FoodieId | null

  fullName: string
  email: string
  phone: string
  balance: number
  isPrepaid: boolean
  lastLoginAt: Dayjs
  hasActiveMatch: boolean
  canBeAddedToBatch: boolean
  isAddedToBatch: boolean
  blockedAt: Dayjs | null

  role: UserRoleEnum
  roles: Role[]
}

export interface UpdateUserProfile {
  firstname: string
  lastname: string

  phone: string
  phoneAlt: string | null
  gender: Gender
  birthdate: Dayjs | null
  housenumber: string
  postalcode: string
  street: string
  city: string
  municipality: string
  district: string
  neighborhood: string
  borough: string | null
  addressComments: string | null
}

export interface UpdateUser {
  email: string
  locale: Language
  balanceThreshold: number
  isPrepaid: boolean
  surveyOnHold: boolean
  userProfile: UpdateUserProfile
}

export interface NonUpdateUser extends UpdateUser {
  id: UserId
  roleId: FoodieId | CookId | null

  createdAt: Dayjs | null
  blockedAt: Dayjs | null

  role: UserTypeEnum
  roles: Role[]

  userProfile: UserProfileWithId
  hasActiveMatch: boolean
  balance: number
  canBeAddedToBatch: boolean
  isAddedToBatch: boolean

  tags: TagConnection[]
}

export interface UserProfile {
  id: UserProfileId
  firstname: string
  lastname: string
  fullName: string

  phone: string
  phoneAlt: string | null
  gender: Gender
  birthdate: Dayjs | null
  housenumber: string
  postalcode: string
  street: string
  city: string
  municipality: string
  district: string
  neighborhood: string
  borough: string | null // Not all municipalities have boroughs
  addressComments: string | null
  promotions: string[] | null
}

export interface UserProfileWithId extends UserProfile {
  id: UserProfileId
}

export interface UserInformation {
  id: UserId
  fullName: string
  email: string
  phone: string
  phoneAlt: string | null

  gender: Gender
  role: UserTypeEnum
  roles: Role[]

  createdAt: Dayjs
  lastLoginAt: Dayjs
  birthdate: Dayjs

  address: Address

  balance: number
  hasActiveMatches: boolean
}

export interface UserBalance {
  id: UserId
  balance: number
  name: string
}

export interface UpdateUserRole {
  roles: Role[]
}

export interface NonUpdateUserRole extends UpdateUserRole {
  id: UserId
  fullName: string
  email: string
}

export interface WebsocketUserRoleUpdate {
  userId: UserId
  role: UserRoleEnum
}

export type UserAutomaticUpdateField = 'is_prepaid'
export type UserAutomaticUpdateParams = {
  id: UserId
  field: UserAutomaticUpdateField
}

export interface PaymentSettingsForm {
  bankaccountNumber: string | null
  bankaccountName: string | null
}

export interface PaymentSettingsFormParams extends PaymentSettingsForm {
  userId: UserId
}

export interface PressModelParams {
  userId: UserId
  press: PressFieldEnum
  pressText: string | null
}

export interface AmbassadorModelParams {
  userId: UserId
  ambassador: PressFieldEnum
  ambassadorText: string | null
}

export interface ExMatchmakerData {
  matchRequests: ExMatchmakerMatchRequest[]
  adminTasks: ExMatchmakerAdminTask[]
  totalMatchesCount: number
}

export interface UserCSVExportParams {
  amountOnly: boolean
  type: UserTypeEnum | 'caretaker'
  hasActiveMatch: NullableBoolean
  mailUpdatesAccepted: NullableBoolean

  availableForMatches: NullableBoolean
  hasBeenContacted: NullableBoolean
  isCloseToOpenMatchRequests: NullableBoolean

  hasHadMatchRequest: NullableBoolean
  caretakerType: CaretakerEnum | null
  municipalities: string[]
  excludeMunicipalities: string[]
  minAccountCreatedAt: Dayjs | null

  minAge: number | undefined
  maxAge: number | undefined

  address: {
    radius: number | null
    postalCode: string | null
    houseNumber: string | null
  }
}

export interface FixUserBalanceResult {
  balance: number
  userWalletBalance: number
  difference: number
}

export interface ContactInformationCardUser {
  userId: UserId
  role: UserTypeEnum

  createdAt: Dayjs | null

  fullName: string
  email: string
  phone: string
  phoneAlt: string | null

  balance: number

  address: Address
  isDeleted: boolean
}

// ----------------- ENUMS -----------------
export enum LanguageEnum {
  Dutch = 'nl',
  English = 'en',
}

export enum GenderEnum {
  Male = 'm',
  Female = 'f',
  PreferNotToSay = '-',
}

export enum PressAvailabilityEnum {
  NotAvailable = 'not-available',
  Unsuitable = 'unsuitable',
  Unknown = 'unknown',
  Suitable = 'suitable',
  Available = 'available',
}

// ----------------- ZOD -----------------
export const LanguageOptions = z.nativeEnum(LanguageEnum)
export type Language = z.infer<typeof LanguageOptions>

export const GenderOptions = z.nativeEnum(GenderEnum).or(z.string())
export type Gender = z.infer<typeof GenderOptions>

export const PressAvailabilityOptions = z.nativeEnum(PressAvailabilityEnum)
export type PressAvailability = z.infer<typeof PressAvailabilityOptions>
