<script setup lang="ts">
import type { FAIconName, TailwindColor, VTabsItem } from '/@src/types/elements-ui'
import { useHead } from '@unhead/vue'
import { useRouteTabs } from '/@src/composable/useRouteTabs'
import { formatCurrency } from '/@src/utils/helpers'
import { LoadingSpinner } from '/@src/models/standardIcons'
import { computed } from 'vue'
import { type LegacyFinanceReportKey } from '/@src/types/finances'
import { useFavicon } from '@vueuse/core'
import { useLegacyFinancesStore } from '/@src/stores/legacy/legacyFinances'

useHead({
  title: 'Legacy Financiële Rapportage | Thuisgekookt Admin',
})

definePage({
  meta: {
    requiresAdmin: true,
    roles: 'finance',
  },
})

const financeStore = useLegacyFinancesStore()

const route = useRoute()
const router = useRouter()

const favicon = computed(() =>
  financeStore.isLoadingAny ? '/loading-favicon.svg' : '/favicon.svg',
)
useFavicon(favicon)

const getIcon = (key: LegacyFinanceReportKey): FAIconName | undefined => {
  if (financeStore.loading[key]) {
    return LoadingSpinner
  } else if (financeStore.errors[key]) {
    return 'fa-x'
  } else if (financeStore.complete[key]) {
    return 'fa-check'
  } else {
    return undefined
  }
}

const getTabColor = (key: LegacyFinanceReportKey): TailwindColor | undefined => {
  if (financeStore.errors[key]) {
    return 'danger'
  } else if (financeStore.complete[key]) {
    return 'success'
  } else {
    return undefined
  }
}

const tabs: VTabsItem<LegacyFinanceReportKey>[] = [
  {
    label: () => {
      if (financeStore.commissionTotal) {
        return `Commissie (${formatCurrency(financeStore.commissionTotal)})`
      } else {
        return 'Commissie'
      }
    },
    value: 'commission',
    icon: () => getIcon('commission'),
    color: () => getTabColor('commission'),
    to: {
      name: '/finances/legacy/commission',
    },
  },
  {
    label: () => {
      if (financeStore.commissionTableTotal) {
        return `Commissie Tabel (${financeStore.commissionTableTotal})`
      } else {
        return 'Commissie Tabel'
      }
    },
    value: 'commissionTable',
    icon: () => getIcon('commissionTable'),
    color: () => getTabColor('commissionTable'),
    to: {
      name: '/finances/legacy/commission-table',
    },
  },
  {
    label: () => {
      if (financeStore.data.yearOverview) {
        const years = financeStore.data.yearOverview.years.toSorted()
        if (years.length === 1) {
          return `Jaaroverzicht (${years[0]})`
        } else {
          const yearString = `${years[0]}-${years.at(-1)}`
          return `Jaaroverzicht (${yearString})`
        }
      } else {
        return 'Jaaroverzicht'
      }
    },
    value: 'yearOverview',
    icon: () => getIcon('yearOverview'),
    color: () => getTabColor('yearOverview'),
    to: {
      name: '/finances/legacy/year-overview',
    },
  },
]

const { activeTab } = useRouteTabs(tabs)

onMounted(() => {
  if (route.name === '/finances') {
    router.replace({
      name: '/finances/totals',
    })
  }
})

onUnmounted(financeStore.stopAll)
</script>

<template>
  <div>
    <VTabs v-model:selected="activeTab" :tabs="tabs" />

    <RouterView v-slot="{ Component }">
      <Transition name="translate-page-x" mode="out-in">
        <KeepAlive>
          <Component :is="Component" />
        </KeepAlive>
      </Transition>
    </RouterView>
  </div>
</template>

<style scoped></style>
